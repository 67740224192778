import { transformImageUrl } from "@ludens-reklame/rubics-v3-sdk/file/utils";
import { Image } from "@ludens-reklame/rubics-v3-sdk/file/types";
import { RichText } from "../../internal-components/richText/RichText.js";

interface Props {
  image?: Image;
  text?: string;
}

const PresentationCard: React.FC<Props> = ({ image, text }) => (
  <div className="presentation-card mw hs vs-xl">
    <div className="container">
      {image && (
        <div className="image-container">
          <img
            src={transformImageUrl(image, { width: 800, height: 800 })}
            alt={image.altText}
          />
        </div>
      )}
      {text && <RichText html={text} />}
    </div>
  </div>
);

export default PresentationCard;
